import styled from 'styled-components'

const TagList = styled.ul`
  display: inline;
  list-style: none;
  margin: 0;
  padding: 0;
  margin-right: 16px;

`

export default TagList