import styled from 'styled-components'

const TagListItem = styled.li`
    background: hsla(0, 0%, 0%, 0.05);
    color: hsla(0, 0%, 0%, 0.7);
    display: inline-block;
    font-size: 80%;
    margin-top: 8px;
    margin-bottom: 8px;
    &:not(:last-of-type) {
        margin-right: 16px;
    }

    &:hover {
        background: hsla(0, 0%, 0%, 0.1);
    }

    a {
        padding: 4px 16px;
        display: block;
    }
`

export default TagListItem