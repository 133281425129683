import styled from 'styled-components'
import Box from './Box'
import { gridTemplateColumns, gridTemplateRows, gridTemplateAreas, gridColumnGap, gridRowGap, gridGap, gridArea, gridAutoFlow, gridAutoRows, gridAutoColumns } from 'styled-system'

// extend Box component
const Grid = styled(Box)({
    display: 'grid'
},
    gridTemplateColumns,
    gridTemplateRows,
    gridTemplateAreas,
    gridColumnGap,
    gridRowGap,
    gridGap,
    gridArea,
    gridAutoFlow,
    gridAutoRows,
    gridAutoColumns,


)

export default Grid